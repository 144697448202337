import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import * as Stats from 'stats.js';

import { noise } from "./perlin.js"

function initiateThree() {

    // Stats.
    var stats = new Stats();
    stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(stats.dom);

    const scene = new THREE.Scene()

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 20)

    const canvas = document.querySelector('#renderer');

    const renderer = new THREE.WebGLRenderer({ canvas })

    renderer.setSize(window.innerWidth, window.innerHeight)

    document.body.appendChild(renderer.domElement)


    camera.position.z = 5

    // Orbit controls.
    const orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.enableKeys = false;
    orbitControls.update();
    // orbitControls.addEventListener('change', renderer);

    // Adding orbit controls to camera (expected by AMI image widgets).
    camera.controls = orbitControls;




    return { scene, camera, renderer, stats }
}

function main() {

    const { scene, camera, renderer, stats } = initiateThree()

    const color = 0xFFFFFF;
    const intensity = 1;
    const light = new THREE.DirectionalLight(color, intensity);
    light.position.set(0, 10, 0);
    light.target.position.set(-5, 0, 0);
    scene.add(light);

    const helper = new THREE.DirectionalLightHelper(light);
    //scene.add(helper);


    const sphere_geometry = new THREE.SphereGeometry(1, 150, 150)
    const spere_material = new THREE.MeshNormalMaterial()
    const sphere = new THREE.Mesh(sphere_geometry, spere_material)
    scene.add(sphere)

    function updateSphere() {

        var time = performance.now() * 0.001

        const k = 3

        for (var i = 0; i < sphere.geometry.attributes.position.array.length / 3; i++) {
            var p_x = sphere.geometry.attributes.position.array[i * 3];
            var p_y = sphere.geometry.attributes.position.array[i * 3 + 1];
            var p_z = sphere.geometry.attributes.position.array[i * 3 + 2];

            const p = new THREE.Vector3(p_x, p_y, p_z);

            p.normalize().multiplyScalar(1 + 0.5 * noise.perlin3(p.x * k + time, p.y * k + time, p.z * k + time));

            sphere.geometry.attributes.position.array[i * 3] = p.x
            sphere.geometry.attributes.position.array[i * 3 + 1] = p.y
            sphere.geometry.attributes.position.array[i * 3 + 2] = p.z

        }

        sphere.geometry.attributes.position.needsUpdate = true
        sphere.geometry.computeVertexNormals();

    }

    function animate() {
        requestAnimationFrame(animate);

        updateSphere()

        stats.update()

        renderer.render(scene, camera);
    }

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
    }

    window.addEventListener('resize', onWindowResize, false);

    animate();

}


main()